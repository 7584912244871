import { defineStore } from "pinia";
import { FormData } from "@/core/types/gws-ap2tki/schedule/FormData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import ApiService from "@/core/services/ApiService";
import { formatDateTimeCustom } from "@/core/helpers/date-format";
import { ListItem } from "@/core/types/gws-ap2tki/schedule/ListItem";
import { EventList } from "@/core/types/gws-ap2tki/schedule/EventList";

export const useAp2TkiScheduleFormStore = defineStore({
  id: "ap2TkiScheduleFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateUpdateId: "",
      stateMealList: [] as string[],
    };
  },
  getters: {
    loading: (state) => {
      return state.stateIsLoading;
    },
    formData: (state) => {
      return state.stateFormData;
    },
    error: (state) => {
      return state.stateError;
    },
    errors: (state) => {
      return state.stateErrors;
    },
  },
  actions: {
    async getDataById() {
      this.setFormData({} as FormData);
      try {
        const response: AxiosResponse<ApiResponse<ListItem>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `schedule-practice/${this.stateUpdateId}`
          );
        const courseData = response.data?.data;
        this.setFormData({
          day: courseData.day,
          course: courseData.course,
          start_time: courseData.start_time,
          end_time: courseData.end_time,
          is_meal: courseData.is_meal,
          meal_list: courseData.meal_list,
        });
        this.stateMealList = courseData.meal_list.split(',');
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async insertData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          day: this.formData.day,
          course: this.formData.course,
          start_time: this.formData.start_time,
          end_time: this.formData.end_time,
          is_meal: this.formData.is_meal,
        };
        if(this.formData.is_meal){
          payload['meal_list'] = this.stateMealList.toString();
        }
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/schedule-practice`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async updateData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          day: this.formData.day,
          course: this.formData.course,
          start_time: this.formData.start_time,
          end_time: this.formData.end_time,
          is_meal: this.formData.is_meal,
        };
        if(this.formData.is_meal){
          payload['meal_list'] = this.stateMealList.toString();
        }
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/schedule-practice/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async deleteData() {
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.delete(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/schedule-practice/${this.stateUpdateId}`
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    setFormData(data: FormData) {
      this.stateFormData = {
        day: data.day,
        course: data.course,
        start_time: data.start_time,
        end_time: data.end_time,
        is_meal: data.is_meal,
        meal_list: data.meal_list,
      };
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
      this.stateError = this.stateErrors.length > 0;
    },
    resetError() {
      this.stateError = false;
      this.setErrors([] as string[]);
    },
  },
});
