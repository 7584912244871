import { defineStore } from "pinia";
import PaginationType from "@/core/types/misc/Pagination";
import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import { FilterData } from "@/core/types/misc/FilterData";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { BlkWrapper } from "@/core/types/gws-master/master/blk/BlkWrapper";
import { ListItem } from "@/core/types/gws-ap2tki/schedule/ListItem";
import { EventList } from "@/core/types/gws-ap2tki/schedule/EventList";

export const useAp2TkiScheduleListStore = defineStore({
  id: "ap2TkiScheduleListStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFilterData: {
        page: 1,
        limit: 9990,
        dir: "DESC",
        sort: "created_at",
      } as FilterData,
      statePagination: new PaginationType(),
      stateDisplayData: [] as EventList[],
      statePaginationLoading: false,
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      this.stateDisplayData = [];
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        sort: this.stateFilterData.sort || "",
      };
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<ListItem[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `schedule-practice?${new URLSearchParams(params).toString()}`
          );
        const rawEvent = response.data?.data;
        rawEvent.forEach((el) => {
          this.stateDisplayData.push({
            groupId: el.id,
            daysOfWeek: [el.day !== '7'? el.day: '0'],
            title: el.course,
            startTime: el.start_time,
            endTime: el.end_time,
            color: `${el.is_meal ? '#057402' : '#223567'}`,
          });
        });
        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async setPage(newPage: number) {
      this.statePagination.handleCurrentPageChange(newPage);
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData();
    },
    async setPageSize(size: number) {
      this.stateFilterData.limit = size;
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData(true);
    },
    setTotalPage(totalPage: number) {
      this.statePagination.totalPage = totalPage;
      this.statePagination.getPaginationStartIndex();
      this.statePagination.getPaginationLastIndex();
    },
  },
});
